<template>
    <div class="save-language-data-modal">
        <NoButtonModal :open="true" id="save_language_data_modal">
            <div>
                <h1>Language Delete Alert</h1>
                <button class="close-corner-x" type="button" @click.prevent="$emit('close')">
                    <span class="material-icons-outlined dark-text">close</span>
                </button>
                <div>Do you want to delete this language?</div>
                <div class="my-2 d-flex justify-content-center">
                    <button type="button" class="primary btn-save" @click="$emit('save')">Yes</button>
                    <button type="button" class="no-bg btn-cancel px-4" @click="$emit('close')">No</button>
                </div>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';

    export default {
        name: 'DeleteLanguageModal',
        components: { NoButtonModal },
    };
</script>

<style scoped>
    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
        border-radius: 10px;
        padding: 5px;
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>
