import { render, staticRenderFns } from "./delete_language_modal.vue?vue&type=template&id=8d5f8198&scoped=true&"
import script from "./delete_language_modal.vue?vue&type=script&lang=js&"
export * from "./delete_language_modal.vue?vue&type=script&lang=js&"
import style0 from "./delete_language_modal.vue?vue&type=style&index=0&id=8d5f8198&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d5f8198",
  null
  
)

export default component.exports